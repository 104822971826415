<template>
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
        <div class="col-span-1 flex items-center flex-wrap">
          <h2 class="text-2xl font-bold mr-3">Limit Requests</h2>
        </div>
      </div>
  
      <div class="border border-solid border-blue-200 rounded overflow-hidden">
        <datatable
          :url="url"
          :ajax="true"
          :ajax-pagination="true"
          :exportable="true"
          :index="true"
          :reverse-index="true"
          :data="limitRequests"
          :columns="columns"
          :loading="loading"
          :on-click="click"
          :query="searchQuery"
          :actions="actions"
          :page-details="true"
          ref="table"
        >
          <template #header>
            <div class="pt-4">
              <search-form
                placeholder="Search users names, emails, phone numbers, bvn, etc."
                class="mb-5"
                v-model="searchQuery"
                @submit="reloadTable"
              />
            </div>
          </template>
        </datatable>
      </div>
  
      <modal
        className="w-full md:w-2/5 xl:w-3/10 pt-10"
        ref="confirmRequestApprovalModal"
        @close="removeError"
      >
        <h4 class="text-2xl font-bold mb-16">Confirm Approval</h4>
  
        <div>
          <template v-if="updateError">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon mr-3">!</span>
              <span>{{ updateError }}</span>
            </div>
          </template>
          <template v-if="selectedLimitRequest">
            <p class="text-gray-500">Name</p>
            <div class="text-xl font-bold">
              {{ selectedLimitRequest.user.name }}
              {{ selectedLimitRequest.user.last_name }}
            </div>
  
            <p class="text-gray-500 mt-4">Current Limit</p>
            <div class="bg-blue-200 rounded p-2 text-center mb-4">
              <div class="text-xl font-bold">
                ₦{{ selectedLimitRequest.current_limit | currency }}
              </div>
            </div>
  
            <p class="text-gray-500 mt-2">Requested Limit</p>
            <div class="bg-blue-200 rounded p-2 text-center mb-10">
              <div class="text-xl font-bold">
                ₦{{ selectedLimitRequest.requested_limit | currency }}
              </div>
            </div>
          </template>
          <form-group
              id="amount"
              v-model="approvalForm.data.amount.value"
              name="amount"
              :form="approvalForm"
            >
              Set New Limit
            </form-group>
  
          <div class="text-center">
            <button
              @click.prevent="updateLimitRequest"
              class="button bg-blue-500 text-white hover:bg-blue-600"
            >
              <!-- <template>Requesting...</template> -->
              <template v-if="loading">Approving...</template>
              <template v-else>Confirm</template>
            </button>
          </div>
        </div>
      </modal>

      <modal
        className="w-full md:w-2/5 xl:w-3/10 pt-10"
        ref="confirmRequestRejectionModal"
        @close="removeError"
      >
        <h4 class="text-2xl font-bold mb-16">Confirm Rejection</h4>

        <div>
            <template v-if="updateError">
                <div class="alert alert-red-soft mb-10">
                    <span class="alert-icon mr-3">!</span>
                    <span>{{ updateError }}</span>
                </div>
            </template>
            <template v-if="selectedLimitRequest">
                <p class="text-gray-500">Name</p>
                <div class="text-xl font-bold">
                    {{ selectedLimitRequest.user.name }}
                    {{ selectedLimitRequest.user.last_name }}
                </div>

                <p class="text-gray-500 mt-4">Current Limit</p>
                <div class="bg-blue-200 rounded p-2 text-center mb-4">
                    <div class="text-xl font-bold">
                        ₦{{ selectedLimitRequest.current_limit | currency }}
                    </div>
                </div>

                <p class="text-gray-500 mt-2">Requested Limit</p>
                <div class="bg-blue-200 rounded p-2 text-center mb-10">
                    <div class="text-xl font-bold">
                        ₦{{ selectedLimitRequest.requested_limit | currency }}
                    </div>
                </div>
            </template>

            <div class="text-center">
            <button
                @click.prevent="updateLimitRequest"
                class="button bg-blue-500 text-white hover:bg-blue-600"
            >
                <!-- <template>Requesting...</template> -->
                <template v-if="loading">Rejecting...</template>
                <template v-else>Confirm</template>
            </button>
            </div>
        </div>
      </modal>
      
      <modal
        className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
        ref="successModal"
      >
        <img
          :src="assets.icons.lg.checkmark"
          class="mx-auto mb-8"
          height="90"
          alt="Check Mark"
        />
  
        <div class="text-lg font-bold mb-4">Success!</div>
        <div class="text-xs mb-10">
          {{ successMessage }}
        </div>
      </modal>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        approvalForm: this.$options.basicForm([
            { name: 'amount', rules: 'number', value: '0'},
        ]),
        url: `${this.$baseurl}/admin/personal/cash/limit-requests`,
        searchQuery: '',
        loading: false,
        limitRequests: [],
        successMessage: '',

        actions: [
          {
            text: 'Approve',
            class:
              'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.openApproveRequestModal,
          },
          {
            text: 'Reject',
            class:
              'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.openRejectRequestModal,
          },
        ],
        columns: [
          {
            name: 'user',
            th: 'customer',
            render: (limitRequest, user) => {
              if (!user) {
                return 'N/A';
              }
              return `${user.name} ${user.last_name}`;
            },
          },
          {
            name: 'user',
            th: 'user id',
            render: (limitRequest, user) => {
              if (!user) {
                return 'N/A';
              }
              return `${user.id}`;
            },
          },
          {
            name: 'user',
            th: 'Phone No',
            render: (limitRequest, user) => {
              if (!user) {
                return 'N/A';
              }
              return `${user.phone}`;
            },
          },
          {
            name: 'current_limit',
            th: 'Current Limit',
            render: (limitRequest, current_limit) =>
              `<span className="text-blue-300">₦ ${this.$options.filters.currency(
                current_limit
              )}</span>`,
          },
          {
            name: 'requested_limit',
            th: 'Requested Limit',
            render: (limitRequest, requested_limit) =>
              `<span className="text-blue-300">₦ ${this.$options.filters.currency(
                requested_limit
              )}</span>`,
          },
          {
            name: 'status',
            th: 'status'
          },
        ],
        selectedLimitRequest: null,
        status: null,
        updateError: null,
      };
    },
    methods: {
      reloadTable() {
        this.$refs.table.loadAjaxData();
      },
      openApproveRequestModal(limitRequest) {
        this.selectedLimitRequest = limitRequest;
        this.status = 'approved';
        this.$refs.confirmRequestApprovalModal.open();
      },
      openRejectRequestModal(limitRequest) {
        this.selectedLimitRequest = limitRequest;
        this.status = 'rejected';
        this.$refs.confirmRequestRejectionModal.open();
      },
      removeError() {
        this.updateError = '';
      },
      async updateLimitRequest() {
        this.loading = true;
        await this.$post({
            url: `${this.$baseurl}/admin/personal/cash/update-limit-request/${this.selectedLimitRequest.user.id}`,
            data: {
                status: this.status,
                amount: this.validateForm(this.approvalForm) ? this.approvalForm.data.amount.value : 0
            },
            headers: this.headers,
            success: () => {
                this.reloadTable();
                this.status == 'approved' ? this.$refs.confirmRequestApprovalModal.close() : this.$refs.confirmRequestRejectionModal.close();
                this.selectedLimitRequest = null;
                this.status = null
                this.successMessage = '';
                this.$refs.successModal.open();
            },
            error: (error) => {
                this.updateError = error.response.data.message;
                this.successMessage = '';
            },
        });
        this.loading = false;
      },
    },
  };
  </script>
  
  <style lang="sass">
  .processor-select-input
    @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
    font-size: 13px
    &:hover
      @apply bg-blue-200 border-blue-200 text-blue-900
    &:focus-within
      @apply border-blue-500 text-blue-500
  .processor-filter-title
    @apply font-bold mb-2 mr-2 text-sm uppercase w-full
  .processor-filter-item
    @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-2 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
    &:hover
      @apply bg-blue-100 border-blue-500 text-blue-500
    &.active
      @apply bg-blue-500 border-blue-500 text-white
  </style>
  